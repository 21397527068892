import logo from "./ausimm-logo-white.svg";
import facebook from "./facebook.svg";
import twitter from "./twitter.svg";
import linkedin from "./linkedin.svg";
import flickr from "./flickr.svg";
import youtube from "./youtube.svg";
import "./App.css";

function App() {
  return (
    <>
      <header className="text-center p-5">
        <div className="container">
          <div className="row">
            <div className="col">
              <a href="https://ausimm.com">
                <img src={logo} alt="AusIMM" />
              </a>
            </div>
          </div>
        </div>
      </header>
      <main className="p-5">
        <div className="container">
          <div className="row text-center">
            <div className="col">
              <h3>The content took too long to load.</h3>
              <br />
              <br />
              <h6>::CLOUDFLARE_ERROR_500S_BOX::</h6>
              <br />
              <br />
              <h4>
                Please wait a few minutes before{" "}
                <a href="https://ausimm.com">
                  <u>trying again</u>
                </a>
                .
              </h4>
              <br />
              <br />
              <br />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h3 className="text-center">Contact us</h3>
              <div className="contact-form py-3">
                <div data-form-block-id="1fb241b1-374e-eb11-a812-000d3ae11abd"></div>
                <div id="dYW0z8Rh25OBDlRJat8vbJ1-SHA3e3DdOSK_nM6FYQGI"></div>
                <div
                  className="d365-mkt-config"
                  style={{ display: "none" }}
                  data-website-id="YW0z8Rh25OBDlRJat8vbJ1-SHA3e3DdOSK_nM6FYQGI"
                  data-hostname="aef61f5ebac647629a4915056d79deba.svc.dynamics.com"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="text-center text-light p-5">
        <div className="container">
          <div className="row mb-4">
            <div className="col"></div>
          </div>
          <div className="row">
            <div className="icons col">
              <a href="https://www.facebook.com/AusIMM/">
                <img src={facebook} alt="Facebook" />
              </a>
              <a href="https://twitter.com/#!/theausimm">
                <img src={twitter} alt="Twitter" />
              </a>
              <a href="https://www.linkedin.com/company/the-ausimm/">
                <img src={linkedin} alt="LinkedIn" />
              </a>
              <a href="https://www.youtube.com/user/TheAusIMM">
                <img src={youtube} alt="YouTube" />
              </a>
              <a href="http://www.flickr.com/photos/ausimm/sets/">
                <img src={flickr} alt="Flickr" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
